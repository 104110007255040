import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import RichTextEditor from "@/components/RichTextEditor";
import ProviderService from "@/services/provider_services";
import GameDemoService from "@/services/game_demo_services";
import Pagination from "@/components/PaginationTable.vue";

export default {
  name: "Game Admin",
  components: { Pagination, RichTextEditor },
  setup(props, context) {
    const inputData = ref({
      id: undefined,
      name: undefined,
      picture: undefined,
      demo_url: undefined,
      narrative: "",
      provider_id: undefined,
    });

    const editButtonStatus = ref(false);

    const providerList = ref([{ id: 0, provider: "", picture: "" }]);
    const tableData = ref([
      {
        id: undefined,
        name: undefined,
        picture: undefined,
        demo_url: undefined,
        narrative: undefined,
        provider_id: undefined,
        provider: {
          id: undefined,
          provider: undefined,
          icon: undefined,
        },
      },
    ]);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let tmpProviders = await ProviderService.getAll(1, 1000);
        providerList.value = tmpProviders.data.data.rows;

        let response = await GameDemoService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = async (data) => {
      inputData.value = { ...data };
      let response = await GameDemoService.getOne(data.id);
      inputData.value = response.data.data;
      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value.id = undefined;
      editButtonStatus.value = false;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus data ${data.provider}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await GameDemoService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        if (inputData.value.id) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        console.log("DATA :::", inputData.value);
        let response = await GameDemoService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await GameDemoService.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      providerList,
      pagination,
      editButtonStatus,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
