<template>
  <quill-editor
    v-model:value="state.content"
    :options="state.editorOption"
    :disabled="state.disabled"
    @change="onEditorChange($event)"
    @image="onInsertImage"
  />
</template>

<script>
import { quillEditor } from "vue3-quill";

import { ref, watch } from "vue";
export default {
  name: "App",
  components: { quillEditor },

  props: {
    editorContent: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const state = ref({
      content: props.modelValue,
      _content: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["link", "image", "video"],
            ],
            handlers: {
              image: () => {
                const url = prompt("Enter image URL:");
                if (url) {
                  const editor = document.querySelector(".ql-editor");
                  editor.innerHTML += `<img src="${url}" />`;
                }
              },
            },
          },
        },
      },
      disabled: false,
    });

    const onEditorChange = ({ html }) => {
      state.value._content = html;
      context.emit("update:modelValue", html);
    };

    const onInsertImage = ($event) => {
      const url = prompt("Enter the URL of the image:");
      if (url) {
        $event.preventDefault();
        const range = this.quill.getSelection();
        this.quill.insertEmbed(range.index, "image", url, "user");
      }
    };

    watch(props, (newProps) => {
      state.value.content = newProps.editorContent;
    });

    return {
      state,
      onEditorChange,
      onInsertImage,
    };
  },
};
</script>
